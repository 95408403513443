import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavController, ToastController } from '@ionic/angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {


  constructor(
    public navCtrl: NavController,
    public afAuth: AngularFireAuth,
    public toastController:ToastController,
  )
  {

  }

  async presentToast(text) {
    const toast = await this.toastController.create({
      message: text,
      duration: 3000,
      color: 'red'
    });
    toast.present();
  }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return new Promise((resolve, reject) => {
        this.afAuth.onAuthStateChanged(function(user) {
          if (user ) {
            resolve(true);
          }
         else{
            setTimeout(() => {
              this.router.navigateByUrl('/login'); 
            }, 100);
            resolve(false);
          }
        });
      });}
  
}
