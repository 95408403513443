import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/userService/auth.guard';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  
      {
        path: 'login',
        loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
      },
      {
        path: 'iframe19',
        loadChildren: () => import('./iframe19/iframe19.module').then( m => m.iframe19PageModule)
      },
      {
        path: 'iframe21',
        loadChildren: () => import('./iframe21/iframe21.module').then( m => m.iframe21PageModule)
      },
      {
        path: 'iframe26',
        loadChildren: () => import('./iframe26/iframe26.module').then( m => m.iframe26PageModule)
      },
      {
        path: 'iframe19en',
        loadChildren: () => import('./iframe19en/iframe19en.module').then( m => m.iframe19enPageModule)
      },
      {
        path: 'iframe21en',
        loadChildren: () => import('./iframe21en/iframe21en.module').then( m => m.iframe21enPageModule)
      },
      {
        path: 'iframe26en',
        loadChildren: () => import('./iframe26en/iframe26en.module').then( m => m.iframe26enPageModule)
      },
      {
        path: 'chambres',
        loadChildren: () => import('./chambres/chambres.module').then( m => m.ChambresPageModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'candidatures',
        loadChildren: () => import('./candidatures/candidatures.module').then( m => m.CandidaturesPageModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'add-admin',
        loadChildren: () => import('./add-admin/add-admin.module').then( m => m.AddAdminPageModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'details-candidature',
        loadChildren: () => import('./details-candidature/details-candidature.module').then( m => m.DetailsCandidaturesPageModule),
        canActivate: [AuthGuard],
      },
    

  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
