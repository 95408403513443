import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(public db: AngularFireDatabase,
    public afAuth: AngularFireAuth) { }

  addUser(idUser, data) {
    if(idUser && data){
      return this.db.list('users').update(idUser, data);
    }
  }
  deleteUser(uid:string)
  {
    return this.db.list('users').remove(uid);  
  }
  deleteCandidature(idCandid:string, data)
  {
    if(idCandid && data){
      return this.db.list('users').update(idCandid, data);
    }   
  }
  signup(data: any) {
    return this.afAuth.createUserWithEmailAndPassword(data.email, data.password).then(value => {
      data.token = value.user.uid;
      this.addUser(value.user.uid, data);
      return 'Client Ajouté avec succés';
    }).catch(err => {
      return err.message;
    });
  }
  logoutUser():Promise<void> {
    return this.afAuth.signOut();     
  }
  getUserbyEmail(email: string)
  {
    return this.db.list('users', ref => ref.orderByChild('email').equalTo(email)).snapshotChanges().pipe(
      map(changes => 
        changes.map(c => ({ key: c.payload, ...c.payload.val()as {}  }),
        )
      )
    );
  }
  UpdateUser(UserKey, data) {
    if(UserKey && data){
      return this.db.list('users').update(UserKey, data);
    }    
  }
  addDossier(UserKey, data){
    if(UserKey && data){
      return this.db.list('users/'+UserKey+"/Dossier").push(data);
    }    
  }
  getUser(UserKey){
    return this.db.object('users/'+UserKey).valueChanges();
   }  
   getAllUser(){
    return this.db.list('users/').snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload, ...c.payload.val() as {} }),
        )
      )
    );
  }
   getUserUid() {
    return this.afAuth.currentUser;
  }

  loginUser(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password)
  }
}

