import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, LoadingController, MenuController, Platform } from '@ionic/angular';
import { LoginService } from './services/login/login.service';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  showHeader = true;
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor(private alertController:AlertController, 
    private authService:LoginService,private loadingController:LoadingController ,
     private platform: Platform,private menuController:MenuController,
     private router:Router) {
      this.router.events.pipe(take(1)).subscribe(event => this.modifyHeader(event));

     }
     modifyHeader(location) { // This method is called many times
      console.log(location.url); // This prints a loot of routes on console
      if (location.url === '/iframe19' || location.url === '/iframe21' || location.url === '/iframe26' || location.url === '/iframe19en' || location.url === '/iframe21en' || location.url === '/iframe26en') {
          this.showHeader = false;
      } else {
          this.showHeader = true;
      }
  }
  async logout() {
    const alert = await this.alertController.create({
      header: 'Voulez-vous vous déconnecter ?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'primary',
          handler: () => { 
          }
        }, {
          text: 'Ok',
          cssClass: 'secondary',
          handler: () => {
            this.logoutAction()
          }
        }
      ]
    });
    await alert.present();
  }


 async logoutAction()
  {
    const loading = await this.loadingController.create({});
    await loading.present();
    this.authService.logoutUser()
      .then(async (res) => {
        this.router.navigateByUrl("/login");
        loading.dismiss();
      })
      .catch((error) => {
        loading.dismiss();
      })
  }
}
